import React from "react";

import { Metadata } from "@webdex/Core";
import { Col, Row } from "@webdex/UI/Grid";

import Header from "./components/Header";
import IntroText from "./components/IntroText";
import StyledIntroduction from "./views/Introduction";

const Introduction = props => {
  const { data: { introductionPage, introduction } } = props;

  return (
    <React.Fragment>
      <Metadata metadata={introductionPage.metadata}/>
      <StyledIntroduction>
        <Row>
          <Col col="8">
            <Header/>
            <IntroText introduction={introduction}/>
          </Col>
        </Row>
      </StyledIntroduction>
    </React.Fragment>
  );
};

export default Introduction;
