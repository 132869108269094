import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import { Anchor } from "@webdex/UI/Button";
import { ResponsiveParagraph } from "@webdex/UI/Paragraph";

import StyledIntroText from "./views/IntroText";

const serializers = {
  types: {
    block: ({ children }) => (
      <ResponsiveParagraph lg="medium" sm="small" xs="small" withMeasure>
        { children }
      </ResponsiveParagraph>
    )
  },
  marks: {
    link: ({ children, mark }) => (
      mark.newTab ? (
        <Anchor href={mark.href} target="_blank" rel="noopener noreferrer">
          { children }
        </Anchor>
      ) : (
        <Anchor href={mark.href}>
          { children }
        </Anchor>
      )
    )
  }
};

const IntroText = props => {
  const { introduction: { _rawText }} = props;

  return (
    <StyledIntroText>
      <BlockContent
        blocks={_rawText}
        serializers={serializers}
      />
    </StyledIntroText>
  );
}

export default IntroText;
