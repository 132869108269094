import React from "react";
import { graphql } from "gatsby";

import IntroductionPageComponent from "@webdex/Pages/Introduction";

const Introduction = props => {
  const { data } = props;

  return (
    <IntroductionPageComponent data={data}/>
  );
};

export const query = graphql`
  query IntroductionPage {
    introductionPage: sanityIntroductionPage {
      ...fieldsOnIntroductionPage
    }
    introduction: sanityIntroduction {
      ...fieldsOnIntroduction
    }
  }
`;

export default Introduction;
