import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { Container } from "@webdex/UI/Grid";

const StyledIntroduction = styled(Container).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "introduction-page")
  };
})`
  ${({ theme: { chrome } }) => `
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: ${ chrome.mobile.footerHeight };
    position: relative;
  `}

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        padding-bottom: ${ chrome.footerHeight };
      }
    `}
  `}
`;

StyledIntroduction.propTypes = {};

StyledIntroduction.defaultProps = {};

export default StyledIntroduction;
